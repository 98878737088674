(function ($) {

	"use strict";

	/* Slider */
	var tab_toggle = jQuery('<div id="tab_toggle" class="mobileOnly"><span class="title"></span><span class="arrow"></span></div>');
	jQuery('#tab').before(tab_toggle);
	jQuery('#tab, #tab_toggle').on('click', function(e){
		e.stopPropagation();
		jQuery('#tab').toggleClass('show');
		tab_toggle.toggleClass('show');

		if(tab_toggle.hasClass('show')) {
			jQuery('#viewport').on('click.tab_toggle', function(){
				tab_toggle.trigger('click');
			});
		} else {
			jQuery('#viewport').off('click.tab_toggle');
		}
	});
	jQuery('#tabs').cycle({ 
		fx: 'fade', 
		speed: 800, 
		timeout: 5000,
		pager: '#tab', 
		height: 'fit',
		before: function(currSlideElement, nextSlideElement, options, forwardFlag) {
			jQuery('#tab_toggle .title').html((jQuery(nextSlideElement).find('h2').html()));
		},
		pagerAnchorBuilder: function(idx, slide) {
			return '<li><a href="#">'+jQuery(slide).find('h2').html()+'</a></li>'; 
		}
	});
	jQuery('.row img.background').fitImage(function(img){
		var container = jQuery(img).closest('div');
		return container;
	}, true);
	/* Endof Slider */

	$('#slideshow').cycle('destroy');
	
	jQuery('.reveal').addClass('unreveal');



	var checkPosition = function() {
		var wTop = jQuery(window).scrollTop();
		var wHeight = jQuery(window).height();
		var wEnd = wTop + wHeight;

		/* Reveal */
		jQuery('.unreveal').each(function(){
			var item = jQuery(this);
			if(!item.hasClass('unreveal')) {
				return true;
			}

			var iTop = item.offset().top;
			var iHeight = item.height();
			var iEnd = iTop + iHeight; 

			if( ( (wTop < iTop) && (wEnd > iTop) && ((wEnd - (wHeight/5)) > iTop ) ) 
			 || ( (wTop > iTop) && (wTop < iEnd) && (wEnd > iEnd) && ((wEnd-iEnd) < (wHeight/5)) ) ){ 
				item.removeClass('unreveal');
			}
		});

	};

	jQuery(window).on('resize', checkPosition);
	jQuery(window).on('scroll', checkPosition);
	setTimeout(function(){
		checkPosition();
	}, 500);

	/*****stop a slide cycle****/
	jQuery( '.blog-featured #tab li ' ).click(function () {
		$('#tabs').cycle('pause');
		setTimeout(function () {
			$('#tabs').cycle('resume');
		}, 20000);
	});


})(jQuery);